<template>
  <component :is="visualizeView" />
</template>

<script>
import { mapState, mapActions } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import alert from '@/mixins/alert';

export default {
  mixins: [alert],

  data() {
    return {
      pending: false
    };
  },

  computed: {
    product() {
      const { product = 'pending' } = this.study;
      return product;
    },

    visualizeView() {
      return `visualize-${this.product}`;
    },

    ...mapState('visualize', ['study'])
  },

  async beforeRouteEnter(to, from, next) {
    next((vm) => vm.loadStudy(to.params.study));
  },

  async beforeRouteUpdate(to, from, next) {
    this.setStudy({});
    this.loadStudy(to.params.study);
    next();
  },

  methods: {
    async loadStudy(study) {
      try {
        const { id } = this.$route.params;
        this.pending = true;

        // if study is empty load the study
        if (isEmpty(study)) {
          const { data: study } = await this.$services.orders.read(id);
          this.setStudy(study);
        } else {
          this.setStudy(study);
        }

        // load study dates
        const { data: dates } = await this.$services.orders.getDates(id);
        this.setDates(dates);

        // load study pois
        const { data: pois } = await this.$services.orders.getPois(id, { params: { summary: false } });
        this.setFeatures(pois);
      } catch (error) {
        this.alertError(error, false);
      } finally {
        this.pending = false;
      }
    },

    ...mapActions('visualize', ['setStudy', 'setDates', 'setFeatures'])
  }
};
</script>
